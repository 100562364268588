
import { Component, Vue, Prop } from 'vue-property-decorator'

import { CallInterface, CallbackInterface } from '../types'
import { toLocalDuration } from '@/utils/durationFormatter'

@Component({
  name: 'EventDetails',
})
export default class extends Vue {
  @Prop({ required: true })
  event!: CallInterface | CallbackInterface

  toLocalDuration = toLocalDuration
}
